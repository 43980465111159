export function IconArrowUpRight({className}: {className?: string}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M19.125 6V15.75C19.125 16.0484 19.0065 16.3345 18.7955 16.5455C18.5845 16.7565 18.2984 16.875 18 16.875C17.7016 16.875 17.4155 16.7565 17.2045 16.5455C16.9935 16.3345 16.875 16.0484 16.875 15.75V8.71875L6.79593 18.7959C6.58458 19.0073 6.29794 19.126 5.99905 19.126C5.70017 19.126 5.41352 19.0073 5.20218 18.7959C4.99083 18.5846 4.8721 18.2979 4.8721 17.9991C4.8721 17.7002 4.99083 17.4135 5.20218 17.2022L15.2812 7.125H8.24999C7.95162 7.125 7.66547 7.00647 7.45449 6.7955C7.24352 6.58452 7.12499 6.29837 7.12499 6C7.12499 5.70163 7.24352 5.41548 7.45449 5.2045C7.66547 4.99353 7.95162 4.875 8.24999 4.875H18C18.2984 4.875 18.5845 4.99353 18.7955 5.2045C19.0065 5.41548 19.125 5.70163 19.125 6Z"
        fill="white"
      />
    </svg>
  );
}
